<template>
  <div class="home">
    <div class="wrapehome">
      <div class="backgroundwelcome"></div>
      <div class="welcome">
        <h3>Selamat datang</h3>
        <p>di booth TOYOTA</p>

        <a-button type="primary" @click="Register">
          Lanjutkan <a-icon type="play-circle" />
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",

  data() {
    return {};
  },

  methods: {
    Register() {
      this.$router.push({ name: "register" });
    },
    Submit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          localStorage.setItem("idUser", values.nickname);
          this.stores = localStorage.getItem("idUser");

          this.$socket.emit("player_join", {
            arena: "",
            input: {
              up: false,
              right: false,
              left: false,
              down: false,
              claw: false,
            },
            name: this.stores,
            prize: "",
          });

          //this.showConfirm(values.nickname);
        }
      });
    },
  },
};
</script>

<style></style>
